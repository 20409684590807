import { stableSort } from 'lib/arrayUtils';
import Breakpoints from 'lib/Breakpoints';
import getLastNonFullWidthLayout from 'lib/getLastNonFullWidthLayoutIndex';

import { getAd } from './getAd';
import adRules from './adRules.json';

export class AdController {
  constructor(vertical, layouts, adRulesKey) {
    /**
     * For backwards compatability we default to using
     * vertical as the adRulesKey
     */
    if (!adRulesKey) {
      /* eslint-disable no-param-reassign */
      adRulesKey = vertical;
    }
    const advertisementRules = adRules?.[adRulesKey];
    if (!advertisementRules) {
      return;
    }

    this.vertical = vertical;
    this.rules = [...advertisementRules];
    this.layouts = layouts;
    this.lastNonFullWidthLayoutIndex = getLastNonFullWidthLayout(layouts);
    this.rule = this.rules.pop();
    this.totalLayoutParsed = 0;
    this.currentPackageIndex = 0;
    this.intervalCounter = 0;
    this.packages = [];
    this.adIndex = 97897689412;
    this.adCount = 1;
  }

  isLayoutExcluded = (type) => {
    const { excludeLayouts } = this.rule;
    return excludeLayouts.includes(type);
  };

  isPackageExcluded = (packageType) => {
    const { excludePackages } = this.rule;
    return excludePackages.includes(packageType);
  };

  isExcludeFirstPackages = (packageType) => {
    const { excludeFirstPackages } = this.rule;
    return excludeFirstPackages.includes(packageType);
  }

  isLastLayoutBeforeTaboola = () => this.totalLayoutParsed === this.lastNonFullWidthLayoutIndex;

  isLastPackageBeforeTaboola = (adPlacement) => {
    const packageLength = this.packages.length - 1;

    // for single zone layouts, don't place before Taboola if it's the last package
    const isPackageBeforeTaboola = adPlacement === packageLength && this.packages[packageLength].type === 'partnerRecirc';
    // if last layout is true
    // if the last package is an ad, don't place an ad before hand
    // since that will render as last package
    // if the last package is not an ad, place ad there if it fits the rule.
    return isPackageBeforeTaboola || (this.isLastLayoutBeforeTaboola()
      && ((this.packages[packageLength].type === 'ad' && packageLength <= adPlacement)
        || (this.packages[packageLength].type !== 'ad' && (packageLength + 1) <= adPlacement)));
  };

  isValidAdPlacement = (adPlacement) => {
    if (adPlacement > this.packages.length || this.isLastPackageBeforeTaboola(adPlacement)) {
      return null;
    }
    return adPlacement;
  };

  setPackage = (packages) => {
    this.currentPackageIndex = 0;
    this.packages = [...packages];
    // sort the zones since it's rendered in order by zone
    // example, teaseList is in zone 2 but it's returned in the
    // array before standardLead, but standardLead is in zone 1
    // but standLead renders first so we place ad after standardlead
    // not teaseList.

    // above: is for news.
    // today: the rails are rendered in opposite order,
    // so we need to sort in descending order.
    let sortFunc;
    if (this.vertical === 'today' || this.vertical === 'msnbc') {
      sortFunc = this.todayMsnbcPackageSorting;
    }
    this.packages = stableSort(this.packages, (pkg) => pkg.zone, sortFunc);
  };

  todayMsnbcPackageSorting = (a, b) => {
    if (a.value > b.value) return -1;
    if (a.value < b.value) return 1;
    // else, values are the same, sort by index
    if (a.index > b.index) return 1;
    if (a.index < b.index) return -1;
    return 0;
  };

  placeAd = (adPlacement, zone = 1) => {
    const { type, customTypeRule } = this.rule;
    let customType = null;
    // support for differnt ad slot types
    // which can be used with rules with repeating ads
    if (customTypeRule) {
      customTypeRule.forEach((rule) => {
        if (rule.placement === this.adCount) {
          customType = rule.type;
        }
      });
    }
    this.adCount += 1;

    this.packages.splice(adPlacement, 0, this.getAd(zone, customType || type));
    this.currentPackageIndex = adPlacement;
  };

  placeAdAfterCertainPackage = (pckageType) => {
    for (let index = this.currentPackageIndex; index < this.packages.length; index += 1) {
      const { type, zone } = this.packages[index];
      if (pckageType === type) {
        const isValidAdPlacement = this.isValidAdPlacement(index + 1);
        if (isValidAdPlacement) {
          this.placeAd(index + 1, zone);
          return true;
        }
        break;
      }
    }
    return false;
  };

  placeAdsIntoPackages = () => {
    const {
      adPlacement, placeAfterPackage, excludePackages, name,
    } = this.rule;
    // this is for today, inserting an ad after first story that is not the magazineLead
    // when we get more requirements on the bigger picture we need to rethink this logic
    // if we don't insert this first ad in the first set of packages, ignore that rule.
    // TODO: rethink logic when we expand this functionality.
    if (adPlacement && excludePackages.length > 0) {
      for (let index = 0; index < this.packages.length; index += 1) {
        const { type, zone } = this.packages[index];
        // if the first package type is one of the types
        // placed on excludeFirstPackages, do not process the ad.
        if (index === 0 && this.isExcludeFirstPackages(type)) break;
        if (name === 'News first ad') break;
        // Do not show ad below Storyline package if Show Ad Below is toggled off in Zone B
        if (!this.isPackageExcluded(type)) {
          this.placeAd(index + 1, zone);
          break;
        }
      }
      return true;
    } if (placeAfterPackage) {
      return this.placeAdAfterCertainPackage(placeAfterPackage);
    }
    this.placeAd(adPlacement);
    return true;
  };

  intervalAdPlacementToPackages = (interval) => {
    for (let index = this.currentPackageIndex; index < this.packages.length; index += 1) {
      const {
        type, zone, metadata: {
          cantShowAdBelow = false,
        } = {},
      } = this.packages[index];
      // skip all the ads, don't count them.

      if (type !== 'ad') {
        // make sure that the current package is not the last one (if package is last one & toggle combine is on, we still need to show ad)
        const isCombinedStorylinePkg = (zone === 1 && cantShowAdBelow && this.vertical === 'news')
              && (index !== this.packages.length - 1);
        const isPackageExcluded = this.isPackageExcluded(type);

        // if fall into any of those cases, set back one interval
        if ((type === 'NativeAd' || isCombinedStorylinePkg || isPackageExcluded)) this.intervalCounter -= 1;

        // increment the interval of package parsed.
        this.intervalCounter += 1;

        if (this.intervalCounter === interval) {
          const isValidAdPlacement = this.isValidAdPlacement(index + 1);
          if (isValidAdPlacement) {
            this.placeAd(isValidAdPlacement, zone);
            this.intervalCounter = 0;
          } else {
            break;
          }
        }
      }
    }
    return this.packages;
  };

  addCustomAds = (layout, packages) => {
    this.totalLayoutParsed += 1;
    if (
      !this.rules
      || this.isLayoutExcluded(layout.type)
      || !packages.length
      || !['news', 'today', 'msnbc'].includes(this.vertical)
    ) {
      return packages;
    }
    this.setPackage(packages);

    // zone B
    // assumptions: teaseList is always in zone B
    if (this.totalLayoutParsed === 2) {
      this.addAdToTeaseList();
    }
    return this.addCustomAdsForVertical();
  };

  addAdToTeaseList = () => {
    const teaseList = this.packages.find((pck) => pck.type === 'teaseList');

    if (!teaseList) {
      return false;
    }

    if (this.vertical === 'today') {
      teaseList.adAboveList = true;
      teaseList.adType = Breakpoints.isSorM() ? 'boxinline' : 'boxrail';
    } else {
      try {
        teaseList.adAboveList = this.layouts[0].packages.length > 0;
      } catch (e) {
        teaseList.adAboveList = false;
      }
      teaseList.adType = 'boxrail';
    }

    return true;
  };

  addCustomAdsForVertical = () => {
    while (this.rule) {
      const { isRecurring } = this.rule;
      if (isRecurring) {
        const { interval } = this.rule;
        return this.intervalAdPlacementToPackages(interval);
      }
      const isAdPlaced = this.placeAdsIntoPackages();
      if (isAdPlaced) {
        this.rule = this.rules.pop();
      } else {
        break;
      }
    }
    return this.packages;
  };

  getAd = (zone = 1, slot = 'boxinline') => {
    this.adIndex += 1;
    return getAd(this.adIndex.toString(), zone, slot);
  };
}
